import { css } from '@emotion/react';
import { Link as MuiLink, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { webLinks } from '@noah-labs/shared-constants';
import type { Maybe } from '../../../generated/types';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { FadeInViewport } from '../../components/layout';
import { MarkdownTypography } from '../../components/typography';

type PpCheckoutInfoWithImage = {
  copy1: Maybe<string> | undefined;
  copy2: Maybe<string> | undefined;
};

export function CheckoutInfoWithImage({
  copy1,
  copy2,
}: PpCheckoutInfoWithImage): React.ReactElement {
  const { isExtraSmall, isSmall } = useBreakpoints();
  const theme = useTheme();
  const styles = {
    container: css`
      background-color: #131313;
      background: url('https://res.cloudinary.com/noahapp/image/upload/v1677492540/Website/limitless-1_sxwhkx.png')
        no-repeat;
      background-size: cover;
      box-shadow: 13px 13px 54px rgba(0, 0, 0, 0.15);
      padding: ${theme.spacing(2, 0)};
      border-radius: ${theme.borderRadius.xl};
      text-decoration: none;
      ${theme.breakpoints.between('sm', 'md')} {
        text-align: center;
      }
      ${theme.breakpoints.down('lg')} {
        background-size: cover;
        padding: ${theme.spacing(6)};
        border-radius: ${theme.borderRadius.lg};
      }
      ${theme.breakpoints.down('md')} {
        background:
          linear-gradient(90deg, #131313 7.77%, rgba(56, 56, 56, 0) 100%),
          url('https://res.cloudinary.com/noahapp/image/upload/v1676992827/Website/limitless-potential_grwnnm.jpg')
            no-repeat;
        background-size: cover;
      }
    `,
    copy: css`
      max-width: 450px;
    `,
    copyWrapper: css`
      width: 100%;
      align-items: center;

      ${theme.breakpoints.up('lg')} {
        width: 50%;
      }
    `,
    image: css`
      width: 100%;
      max-width: 350px;
    `,
    imageWrapper: css`
      ${theme.breakpoints.up('lg')} {
        width: 50%;
        align-items: flex-end;
      }
    `,
  };
  return (
    <Stack
      alignItems={isSmall ? 'center' : 'flex-end'}
      component={MuiLink}
      css={styles.container}
      href={`${webLinks.app.path}/wallet/buy/btc/current`}
      target="_blank"
    >
      <Stack
        alignItems="center"
        direction={isSmall ? 'column' : 'row'}
        justifyContent="center"
        width="100%"
      >
        <Stack css={styles.imageWrapper}>
          <img
            alt="bitcoin graph"
            css={styles.image}
            src="https://res.cloudinary.com/noahapp/image/upload/v1676993868/Website/limitless-potential-screen_1_vscp48.png"
          />
        </Stack>
        <FadeInViewport timeout={500}>
          <Stack css={styles.copyWrapper}>
            <Stack css={styles.copy} spacing={2}>
              <MarkdownTypography color="text.white" variant={isExtraSmall ? 'bodyHeadingS' : 'h6'}>
                {copy1}
              </MarkdownTypography>
              <MarkdownTypography
                color="text.white"
                variant={isExtraSmall ? 'paragraphBodyM' : 'subHeadingS'}
              >
                {copy2}
              </MarkdownTypography>
            </Stack>
          </Stack>
        </FadeInViewport>
      </Stack>
    </Stack>
  );
}
