import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { ArrowsDown, FadeInViewport } from '../../components/layout';
import { MarkdownTypography } from '../../components/typography';
import { CheckoutInnerBanner } from './CheckoutInnerBanner';

type PpCheckoutBanner = {
  background: string;
  cssObject: SerializedStyles;
  linkToBuy?: boolean;
  subtitle: string | null | undefined;
  title: string | null | undefined;
};

export function CheckoutBanner({
  background,
  cssObject,
  linkToBuy,
  subtitle,
  title,
}: PpCheckoutBanner): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall, isSmall } = useBreakpoints();
  const styles = {
    container: css`
      width: 100%;
    `,
  };

  return (
    <FadeInViewport timeout={750}>
      <div css={styles.container}>
        <CheckoutInnerBanner background={background} cssObject={cssObject} linkToBuy={linkToBuy}>
          <Stack spacing={isSmall ? 1.5 : 5.5}>
            <MarkdownTypography
              color="text.white"
              variant={isExtraSmall ? 'paragraphBodySBold' : 'subHeadingM'}
            >
              {subtitle}
            </MarkdownTypography>
            <MarkdownTypography color="text.white" variant={isExtraSmall ? 'bodyHeadingS' : 'h3'}>
              {title}
            </MarkdownTypography>
          </Stack>
          {!isSmall && (
            <ArrowsDown
              colorBottom={theme.palette.websiteBrand.primary}
              colorTop={theme.palette.websiteBrand.greyscale.white}
            />
          )}
        </CheckoutInnerBanner>
      </div>
    </FadeInViewport>
  );
}
