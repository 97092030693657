import type { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Link as MuiLink, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { webLinks } from '@noah-labs/shared-constants';
import type {
  MarkdownRemarkFrontmatterCarouselItems,
  MarkdownRemarkFrontmatterIntroCards,
  Maybe,
} from '../../../generated/types';
import { CustomCarousel, CustomCarouselArrow } from '../../components/carousel';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { CheckoutCarouselCard } from './CheckoutCarouselCard';
import { CheckoutInfoWithImage } from './CheckoutInfoWithImage';
import { CheckoutIntroCard } from './CheckoutIntroCard';

type PpCheckoutIntroSection = {
  carouselItems: Maybe<Array<Maybe<MarkdownRemarkFrontmatterCarouselItems>>> | undefined;
  copy1: string | null | undefined;
  copy2: string | null | undefined;
  introCards?: Maybe<Array<Maybe<MarkdownRemarkFrontmatterIntroCards>>>;
};

export function CheckoutIntroSection({
  carouselItems,
  copy1,
  copy2,
  introCards,
}: PpCheckoutIntroSection): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall, isMedium, isSmall } = useBreakpoints();
  function getSlidePercentage(): number {
    if (isExtraSmall) {
      return 80;
    }
    if (isSmall) {
      return 50;
    }
    return 40;
  }
  const styles = {
    carousel: css`
      width: 100%;
      position: relative;
      height: 350px;
      .slide {
        padding: ${theme.spacing(1)};
      }
      ${theme.breakpoints.down('lg')} {
        .slider-wrapper,
        .carousel {
          padding: ${theme.spacing(1, 0, 2)};
        }
      }
      ${theme.breakpoints.up('sm')} {
        max-width: 550px;
        margin-bottom: ${theme.spacing(4)};
        ::before,
        ::after {
          content: '';
          width: 10%;
          height: 325px;
          position: absolute;
          z-index: 1;
          top: ${theme.spacing(4)};
        }
        ::before {
          left: 0;
          background: linear-gradient(90deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
        }
        ::after {
          right: 0;
          background: linear-gradient(-90deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
        }
      }
      ${theme.breakpoints.up('lg')} {
        max-width: 700px;
        ::before,
        ::after {
          top: 0;
          height: 100%;
        }
      }
    `,
    container: css`
      margin-top: ${theme.spacing(10)};
    `,
    image: css`
      width: 100%;
      max-width: 700px;
      border-radius: ${theme.borderRadius.xl};
      ${theme.breakpoints.down('lg')} {
        max-width: none;
        width: 100%;
        border-radius: ${theme.borderRadius.lg};
        margin: ${theme.spacing(2, 0)};
        max-height: 350px;
      }
      ${theme.breakpoints.down('sm')} {
        max-height: none;
      }
    `,
  };
  return (
    <Stack css={styles.container} spacing={10}>
      <Stack spacing={isMedium ? 10 : 12}>
        {introCards?.map((info, index) => {
          if (!info) {
            return null;
          }
          if (index === introCards.length - 1) {
            return (
              <CheckoutIntroCard
                key={info.id}
                copy={info.cardCopy}
                link={info.link}
                title={info.cardTitle}
              >
                <Stack alignItems="center">
                  {carouselItems && (
                    <CustomCarousel
                      autoPlay
                      centerMode
                      infiniteLoop
                      preventMovementUntilSwipeScrollTolerance
                      swipeable
                      centerSlidePercentage={getSlidePercentage()}
                      css={styles.carousel}
                      interval={3000}
                      renderArrowNext={(onClickHandler, hasNext, label): false | ReactNode =>
                        hasNext && (
                          <CustomCarouselArrow
                            label={label}
                            side="right"
                            onClick={onClickHandler}
                          />
                        )
                      }
                      renderArrowPrev={(onClickHandler, hasPrev, label): false | ReactNode =>
                        hasPrev && (
                          <CustomCarouselArrow label={label} side="left" onClick={onClickHandler} />
                        )
                      }
                      showArrows={!isMedium}
                      showIndicators={isMedium}
                      showStatus={false}
                      showThumbs={false}
                      swipeScrollTolerance={50}
                    >
                      {carouselItems?.map((item, newIndex) => (
                        <CheckoutCarouselCard
                          key={item?.id}
                          comingSoon={item?.comingSoon}
                          copy={item?.copy}
                          index={newIndex}
                        />
                      ))}
                    </CustomCarousel>
                  )}
                </Stack>
              </CheckoutIntroCard>
            );
          }
          return (
            <CheckoutIntroCard
              key={info.id}
              copy={info.cardCopy}
              link={info.link}
              title={info.cardTitle}
            >
              <Stack
                alignItems="center"
                component={MuiLink}
                href={`${webLinks.app.path}/wallet/buy/btc/current`}
                target="_blank"
              >
                <img
                  alt={info.cardTitle as string}
                  css={styles.image}
                  src={info.cardImage as string}
                />
              </Stack>
            </CheckoutIntroCard>
          );
        })}
      </Stack>
      <CheckoutInfoWithImage copy1={copy1} copy2={copy2} />
    </Stack>
  );
}
