import type { ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Chip, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FadeInViewport } from '../../components';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { useLocalizedCopy } from '../../components/hooks/useLocalizedCopy';
import { CheckoutGridIcon } from './CheckoutGridIcon';

type PpGridCard = {
  comingSoon: boolean | null | undefined;
  copy: string | null | undefined;
  cssObject?: SerializedStyles;
  gridItem?: boolean | null | undefined;
  title: string | null | undefined;
};

export function GridCard({
  comingSoon,
  copy,
  cssObject,
  gridItem = false,
  title,
}: PpGridCard): ReactElement {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const { comingSoonCopy } = useLocalizedCopy();

  const styles = {
    card: css`
      width: 100%;
      height: auto;
      margin: ${theme.spacing(5, 0)};
      align-items: center;
      text-align: center;
      ${theme.breakpoints.up('sm')} {
        height: 200px;
        padding-right: ${theme.spacing(2)};
      }
      ${theme.breakpoints.up('md')} {
        padding-right: ${theme.spacing(4)};
      }
      ${theme.breakpoints.up('lg')} {
        padding-right: 0;
        text-align: left;
        align-items: flex-start;
        margin: ${theme.spacing(1, 0)};
      }
    `,
    gridCardStack: css`
      align-items: center;
      ${theme.breakpoints.up('lg')} {
        align-items: flex-start;
      }
    `,
  };

  if (gridItem) {
    return (
      <Grid item css={[styles.card, cssObject]} lg={4} width={isExtraSmall ? '100%' : '50%'}>
        <Stack css={styles.gridCardStack} spacing={2}>
          <CheckoutGridIcon name={title} />
          <FadeInViewport timeout={750}>
            <Stack direction="row" spacing={1} width="100%">
              <Typography
                color="text.dark"
                sx={{ display: 'block', width: '100%' }}
                variant={isExtraSmall ? 'bodyHeadingS' : 'subHeadingS'}
              >
                {title}
              </Typography>
              {comingSoon && (
                <Stack justifyContent="center">
                  <Chip label={comingSoonCopy} />
                </Stack>
              )}
            </Stack>
          </FadeInViewport>
          <FadeInViewport timeout={750}>
            <Typography
              color="text.dark"
              variant={isExtraSmall ? 'bodyHeadingXS' : 'paragraphBodyM'}
            >
              {copy}
            </Typography>
          </FadeInViewport>
        </Stack>
      </Grid>
    );
  }

  return (
    <Stack css={[styles.card, cssObject]} spacing={2}>
      <CheckoutGridIcon name={title} />
      <FadeInViewport timeout={750}>
        <Stack direction="row" spacing={1}>
          <Typography color="text.dark" variant={isExtraSmall ? 'bodyHeadingS' : 'subHeadingS'}>
            {title}
          </Typography>
          {comingSoon && (
            <Stack justifyContent="center">
              <Chip label={comingSoonCopy} />
            </Stack>
          )}
        </Stack>
      </FadeInViewport>
      <FadeInViewport timeout={750}>
        <Typography color="text.dark" variant={isExtraSmall ? 'bodyHeadingXS' : 'paragraphBodyM'}>
          {copy}
        </Typography>
      </FadeInViewport>
    </Stack>
  );
}
