import type { SVGProps } from 'react';

export function SvgBitrefill(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#bitrefill_svg__a)">
        <path
          d="M23.628 14.905c-1.61 6.426-8.113 10.344-14.537 8.733S-1.25 15.52.36 9.095C1.957 2.669 8.476-1.25 14.9.362c6.424 1.61 10.339 8.118 8.744 14.543z"
          fill="currentColor"
        />
        <path
          d="M7 10.378 9.996 8.24v9.21h3.663c1.387 0 2.345-.855 2.345-2.105s-.958-2.105-2.345-2.105h-2.003l2.568-2.467c.719-.69.873-1.118.873-1.595 0-.938-.753-1.612-1.969-1.612h-6.11V5h6.624c2.585 0 4.434 1.497 4.434 3.668 0 1.036-.223 1.793-1.027 2.566l-.531.51c1.455.69 2.482 2.072 2.482 3.865C19 18.207 16.843 20 13.796 20H7.017v-9.638z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="bitrefill_svg__a">
          <path d="M0 0h24v24H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
