import ChevronRight from '@mui/icons-material/ChevronRight';
import type { ButtonProps, LinkProps } from '@mui/material';
import { useLocalizedCopy } from '../hooks/useLocalizedCopy';
import { LocalizedButton } from '../i18n/LocalizedButton';

type PpLearnMoreButton = ButtonProps & LinkProps & { href: string };

export function LearnMoreButton({ href, ...buttonProps }: PpLearnMoreButton): React.ReactElement {
  const { learnMoreCopy } = useLocalizedCopy();

  return (
    <LocalizedButton
      {...buttonProps}
      color="primary"
      endIcon={<ChevronRight />}
      href={href}
      sx={{ span: { marginLeft: 0 } }}
      variant="text"
    >
      {learnMoreCopy}
    </LocalizedButton>
  );
}
