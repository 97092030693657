import { useTheme } from '@mui/material/styles';
import { ArrowDownThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ArrowDownThickIcon';
import { ArrowUpThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ArrowUpThickIcon';
import { BanknoteIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BanknoteIcon';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import { CustomizeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CustomizeIcon';
import { GrowIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/GrowIcon';
import { ImportExportHorizontalIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ImportExportHorizontalIcon';
import { InfoIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/InfoIcon';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import { PlaceholderIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlaceholderIcon';
import { ReceiveIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ReceiveIcon';
import { WarningIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/WarningIcon';
import { bitcoinIconOverride } from './utils';

type PpCheckoutGridIcon = {
  name: string | null | undefined;
};

export function CheckoutGridIcon({ name }: PpCheckoutGridIcon): React.ReactElement {
  const theme = useTheme();

  switch (name?.toLowerCase()) {
    case 'buy':
    case 'comprar':
      return <ArrowDownThickIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'sell':
    case 'vender':
      return <ArrowUpThickIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'pay':
    case 'pagar':
      return <ImportExportHorizontalIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'receive':
    case 'reciba':
      return <ReceiveIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'access':
    case 'acceso':
      return <BitcoinIcon css={bitcoinIconOverride(theme.palette.websiteBrand.primary)} />;
    case 'secure':
    case 'asegure':
      return <LockIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'grow':
    case 'crecer':
      return <GrowIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'customize':
    case 'personalizar':
      return <CustomizeIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'earn':
    case 'ganar':
      return <BanknoteIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'no markups':
    case 'sin recargo':
      return <CircleTickIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'no limits':
    case 'sin límites':
      return <WarningIcon htmlColor={theme.palette.websiteBrand.primary} />;
    case 'no hassle':
    case 'sin complicaciones':
      return <InfoIcon htmlColor={theme.palette.websiteBrand.primary} />;
    default:
      return <PlaceholderIcon htmlColor={theme.palette.websiteBrand.primary} />;
  }
}
