import type { SVGProps } from 'react';

export function SvgGrow(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 23 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={7} rx={2.738} width={9} y={15.861} />
      <rect fill="currentColor" height={22.746} rx={2.738} width={9.358} x={13.642} y={0.484} />
    </svg>
  );
}
