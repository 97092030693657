import type { SVGProps } from 'react';

export function SvgCustomize(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 25 26"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49 11.547a1.674 1.674 0 0 0 0 2.367L3.876 16.3v3.378c0 .924.75 1.673 1.674 1.673h3.377l2.39 2.39a1.673 1.673 0 0 0 2.366 0l2.39-2.39h3.373c.925 0 1.674-.75 1.674-1.673v-3.374l2.39-2.39a1.673 1.673 0 0 0 0-2.367l-2.39-2.39V5.782c0-.924-.75-1.674-1.674-1.674H16.07l-2.387-2.386a1.674 1.674 0 0 0-2.367 0L8.93 4.107H5.55c-.924 0-1.674.75-1.674 1.674v3.38z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.714}
      />
    </svg>
  );
}
