import { css } from '@emotion/react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterFaqItems } from '../../../generated/types';
import { FadeInViewport } from '../../components';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { LocalizedButton } from '../../components/i18n/LocalizedButton';
import { MarkdownTypography } from '../../components/typography';
import { CheckoutAccordion } from './CheckoutAccordion';

type PpCheckoutFaq = {
  copy: string | null | undefined;
  faqItems?: MarkdownRemarkFrontmatterFaqItems[] | null | undefined;
  label: string | null | undefined;
};

export function CheckoutFaq({ copy, faqItems, label }: PpCheckoutFaq): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall, isMedium } = useBreakpoints();
  const styles = {
    container: css`
      background-color: ${theme.palette.websiteBrand.greyscale.lighterGrey};
      padding: ${theme.spacing(11, 14)};
      border-radius: ${theme.borderRadius.xl};
      flex-direction: row;
      ${theme.breakpoints.down('lg')} {
        background-color: ${theme.palette.websiteBrand.greyscale.white};
        padding: ${theme.spacing(0, 3)};
        flex-direction: column;
        max-width: ${`${theme.breakpoints.values.md}px`};
      }
    `,
    stack: css`
      margin-top: ${theme.spacing(20)};
      ${theme.breakpoints.down('lg')} {
        margin-top: ${theme.spacing(10)};
        padding: ${theme.spacing(3, 0)};
        width: 100vw;
        position: relative;
        left: ${theme.spacing(-3)};
        align-items: center;
      }
    `,
  };
  return (
    <Stack css={styles.stack}>
      <FadeInViewport style={{ transitionDelay: '250ms' }} timeout={1000}>
        <Stack css={styles.container} justifyContent="space-between">
          <Stack alignItems="flex-start" spacing={2} width={isMedium ? '100%' : '45%'}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant={isExtraSmall ? 'bodyHeadingM' : 'h6'}>FAQs</Typography>
              <Chip label="Learn" />
            </Stack>
            <MarkdownTypography
              color="text.dark"
              variant={isExtraSmall ? 'paragraphBodyMBold' : 'subHeadingM'}
            >
              {copy}
            </MarkdownTypography>
            <LocalizedButton
              color="primary"
              endIcon={<ChevronRight />}
              href="/blog/noah-crypto-glossary"
              variant="text"
            >
              {label}
            </LocalizedButton>
          </Stack>
          <CheckoutAccordion faqItems={faqItems} />
        </Stack>
      </FadeInViewport>
    </Stack>
  );
}
