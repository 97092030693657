import type { SVGProps } from 'react';

export function SvgArrowDownThick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 22 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.065 23.479a1.438 1.438 0 0 1-2.191-.003L.716 12.659a.719.719 0 0 1 .549-1.183h5.076V2.422c0-.794.644-1.438 1.438-1.438h6.39c.794 0 1.438.644 1.438 1.438v9.054h5.128c.614 0 .945.718.548 1.185z"
        fill="currentColor"
      />
    </svg>
  );
}
