//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgBanknote } from '../icons/Banknote';

export function BanknoteIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgBanknote} data-qa="BanknoteIcon">
      <svg />
    </SvgIcon>
  );
}
