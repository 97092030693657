import type { ReactElement } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Link as MuiLink, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { webLinks } from '@noah-labs/shared-constants';

type PpCheckoutInnerBanner = {
  background: string;
  cssObject?: SerializedStyles;
  linkToBuy?: boolean;
} & PpWC;

export function CheckoutInnerBanner({
  background,
  children,
  cssObject,
  linkToBuy = false,
}: PpCheckoutInnerBanner): ReactElement {
  const theme = useTheme();
  const styles = {
    banner: css`
      align-items: center;
      background-image: url(${background});
      background-repeat: no-repeat;
      border-radius: ${theme.borderRadius.xl};
      box-shadow: 8px 16px 20px 2px rgba(0, 0, 0, 0.25);
      padding: ${theme.spacing(11, 0, 0)};
      text-decoration: none;
      width: 100%;
      ${theme.breakpoints.down('md')} {
        border-radius: ${theme.borderRadius.md};
        padding: ${theme.spacing(6, 0)};
      }
    `,
  };
  if (linkToBuy) {
    return (
      <Stack
        component={MuiLink}
        css={[styles.banner, cssObject]}
        href={`${webLinks.app.path}/wallet/buy/btc/current`}
        target="_blank"
      >
        {children}
      </Stack>
    );
  }
  return <Stack css={[styles.banner, cssObject]}>{children}</Stack>;
}
