import { css } from '@emotion/react';
import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import type { Maybe } from '../../../generated/types';
import { FadeInViewport } from '../../components';
import { LearnMoreButton } from '../../components/buttons';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { MarkdownTypography } from '../../components/typography';

type PpCheckoutIntroCard = {
  copy: Maybe<string> | undefined;
  link?: Maybe<string> | undefined;
  title: Maybe<string> | undefined;
} & PpWC;

export function CheckoutIntroCard({
  children,
  copy,
  link,
  title,
}: PpCheckoutIntroCard): React.ReactElement {
  const { isExtraSmall } = useBreakpoints();
  const theme = useTheme();
  const styles = {
    card: css`
      align-items: center;
      :nth-of-type(odd) {
        flex-direction: row;
        ${theme.breakpoints.down('lg')} {
          flex-direction: column;
        }
      }
      :nth-of-type(even) {
        flex-direction: row-reverse;
        ${theme.breakpoints.down('lg')} {
          flex-direction: column;
        }
      }
      ${theme.breakpoints.down('sm')} {
        align-items: flex-start;
      }
    `,
    childrenWrapper: css`
      ${theme.breakpoints.up('sm')} {
        padding-top: ${theme.spacing(5)};
      }
    `,
    copy: css`
      max-width: 450px;
      height: 100%;
      display: block;
      margin: 0 auto;
      ${theme.breakpoints.down('lg')} {
        text-align: center;
        align-items: center;
      }
      ${theme.breakpoints.down('sm')} {
        max-width: none;
        text-align: left;
      }
    `,
  };
  return (
    <Grid container css={styles.card}>
      <Grid item lg={6} xs={12}>
        <FadeInViewport timeout={1000}>
          <Stack css={styles.copy} spacing={2} width="100%">
            <MarkdownTypography component="div" variant={isExtraSmall ? 'bodyHeadingS' : 'h6'}>
              {title}
            </MarkdownTypography>
            <MarkdownTypography
              color="text.dark"
              component="div"
              variant={isExtraSmall ? 'paragraphBodyM' : 'subHeadingS'}
            >
              {copy}
            </MarkdownTypography>
            {link && <LearnMoreButton href={link} />}
          </Stack>
        </FadeInViewport>
      </Grid>
      <FadeInViewport style={{ transitionDelay: '250ms' }} timeout={1000}>
        <Grid item css={styles.childrenWrapper} lg={6} xs={12}>
          {children}
        </Grid>
      </FadeInViewport>
    </Grid>
  );
}
