import type { SVGProps } from 'react';

export function SvgReceive(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 17"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.415 15.044c.455.538 1.285.54 1.742.003l7.326-8.599a.572.572 0 0 0-.435-.942h-4.076V0H22a2 2 0 0 1 2 2v12.667a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6.607v5.506H4.572c-.486 0-.75.57-.436.94z"
        fill="currentColor"
      />
    </svg>
  );
}
