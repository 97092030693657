import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { NativeSelect } from '@mui/material';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';

type PpBuySellNativeSelect = {
  id: string;
  label: string;
  options: (string | null | undefined)[] | undefined;
  setState: Dispatch<SetStateAction<string | null>>;
  state: string | null;
};

export function BuySellNativeSelect({
  id,
  label,
  options,
  setState,
  state,
}: PpBuySellNativeSelect): ReactElement | null {
  if (!options) {
    return null;
  }

  return (
    <NativeSelect
      disableUnderline
      fullWidth
      IconComponent={ChevronDownIcon}
      id={id}
      inputProps={{
        variant: 'outlined',
      }}
      value={state || ''}
      variant="outlined"
      onChange={(e): void => setState(e.target.value)}
    >
      <option>{label}</option>
      {options.map((option) => {
        if (!option) {
          return null;
        }
        return (
          <option key={option} value={option}>
            {option}
          </option>
        );
      })}
    </NativeSelect>
  );
}
