/* eslint-disable react/no-multi-comp */
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import ReactPlayer from 'react-player';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { FadeInViewport } from '../../components/layout';
import { bitcoinIconOverride } from './utils';

type PpCheckoutVideoSection = {
  copy1: string | null | undefined;
  copy2: string | null | undefined;
  copy3: string | null | undefined;
  copy4: string | null | undefined;
  video: string | null | undefined;
};

export function VideoWrapper({ children }: PpWC): React.ReactElement {
  const theme = useTheme();
  const styles = {
    videoWrapper: css`
      width: 50%;
      overflow: hidden;
      box-shadow: 8px 8px 25px 5px rgba(0, 0, 0, 0.25);
      border-radius: ${theme.borderRadius.md};
      ${theme.breakpoints.down('md')} {
        box-shadow: none;
        width: 65%;
      }
      ${theme.breakpoints.down('sm')} {
        width: 100%;
      }
    `,
  };
  return (
    <Stack css={styles.videoWrapper} justifyContent="center">
      {children}
    </Stack>
  );
}

export function CheckoutVideoSection({
  copy1,
  copy2,
  copy3,
  copy4,
  video,
}: PpCheckoutVideoSection): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall, isMedium } = useBreakpoints();
  const styles = {
    stack: css`
      overflow: hidden;
      position: relative;
      margin: ${theme.spacing(10, 0)};
    `,
  };
  return (
    <Stack css={styles.stack} spacing={isMedium ? 10 : 20} textAlign="center">
      <Stack alignItems="center" spacing={10}>
        <Stack>
          <Typography color="text.dark" variant={isExtraSmall ? 'paragraphBodyLBold' : 'h6'}>
            {copy1}
          </Typography>
          <Typography color="text.dark" variant={isExtraSmall ? 'paragraphBodyLBold' : 'h6'}>
            {copy2}
          </Typography>
        </Stack>
        {video && (
          <ReactPlayer
            controls
            playsinline
            config={{
              file: {
                attributes: {
                  poster:
                    'https://res.cloudinary.com/noahapp/image/upload/c_scale,h_720,w_1280/v1678096764/Website/CKO_-_Social_preview_1000x500-2_a9bfsi.jpg',
                },
              },
            }}
            height="100%"
            url={video}
            width="100%"
            wrapper={VideoWrapper}
          />
        )}
      </Stack>
      <Stack alignItems="center" spacing={5} textAlign="center">
        <Stack maxWidth={`${theme.breakpoints.values.md}px`} spacing={2}>
          <FadeInViewport style={{ transitionDelay: '250ms' }} timeout={750}>
            <Typography color="text.secondary" variant={isExtraSmall ? 'bodyHeadingXS' : 'h6'}>
              {copy3}
            </Typography>
          </FadeInViewport>
          <FadeInViewport style={{ transitionDelay: '500ms' }} timeout={750}>
            <Typography variant={isExtraSmall ? 'bodyHeadingL' : 'h2'}>{copy4}</Typography>
          </FadeInViewport>
        </Stack>
        <img
          alt="google pay apple pay"
          src="https://res.cloudinary.com/noahapp/image/upload/v1676646884/Website/Logos_fmupzm.png"
          width="200px"
        />
        <Stack alignItems="center" spacing={5}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <BitcoinIcon css={bitcoinIconOverride(theme.palette.websiteBrand.primary)} />
            <Typography color="text.secondary" variant="buttonS">
              Powered by Bitcoin.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
