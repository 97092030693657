//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgGrow } from '../icons/Grow';

export function GrowIcon(props: Omit<SvgIconProps, 'component' | 'children'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgGrow} data-qa="GrowIcon">
      <svg />
    </SvgIcon>
  );
}
