import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { FadeInViewport } from '../../components/layout';

export function GridCardTitle({ title }: { title: string }): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const styles = {
    title: css`
      display: block;
      text-align: center;
      text-transform: capitalize;
      border-bottom: 1px solid ${theme.palette.websiteBrand.greyscale.lightGrey};
      padding-bottom: ${theme.spacing(1)};
      margin-bottom: ${theme.spacing(1)};
      margin-top: ${theme.spacing(4)};
      ${theme.breakpoints.up('md')} {
        margin-right: ${theme.spacing(4)};
      }
      ${theme.breakpoints.up('lg')} {
        margin-right: 0;
        text-align: left;
      }
    `,
  };
  return (
    <FadeInViewport timeout={500}>
      <div>
        <Typography
          color="text.secondary"
          css={styles.title}
          variant={isExtraSmall ? 'bodyHeadingS' : 'bodyHeadingL'}
        >
          {title}
        </Typography>
      </div>
    </FadeInViewport>
  );
}
