import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type {
  MarkdownRemarkFrontmatterFlexible,
  MarkdownRemarkFrontmatterGlobal,
  MarkdownRemarkFrontmatterInstant,
  MarkdownRemarkFrontmatterTransparent,
  Maybe,
} from '../../../generated/types';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { GridGlobalInstantFlexible } from './GridGlobalInstantFlexible';
import { GridTransparent } from './GridTransparent';

type PpCheckoutFeaturesGrid = {
  flexible: Maybe<Array<Maybe<MarkdownRemarkFrontmatterFlexible>>>;
  global: Maybe<Array<Maybe<MarkdownRemarkFrontmatterGlobal>>>;
  instant: Maybe<Array<Maybe<MarkdownRemarkFrontmatterInstant>>>;
  transparent: Maybe<Array<Maybe<MarkdownRemarkFrontmatterTransparent>>>;
};

export function CheckoutFeaturesGrid({
  flexible,
  global,
  instant,
  transparent,
}: PpCheckoutFeaturesGrid): React.ReactElement {
  const theme = useTheme();
  const { isMedium } = useBreakpoints();

  const styles = {
    gridContainer: css`
      ${theme.breakpoints.up('lg')} {
        margin-left: ${theme.spacing(-24)};
        padding: ${theme.spacing(0, 4)};
      }
    `,
  };

  return (
    <Grid container columnSpacing={isMedium ? 0 : 24} css={styles.gridContainer}>
      <GridGlobalInstantFlexible data={global} title="global" />
      <GridGlobalInstantFlexible data={instant} title="instant" />
      <GridGlobalInstantFlexible data={flexible} title="flexible" />
      <GridTransparent data={transparent} />
    </Grid>
  );
}
