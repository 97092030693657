import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { ArrowsDown } from '../../components/layout';
import { CheckoutBanner } from './CheckoutBanner';

type PpCheckoutHero = {
  heroCopy1: string | null | undefined;
  heroCopy2: string | null | undefined;
  heroSubtitle: string | null | undefined;
  heroTitle: string | null | undefined;
};

export function CheckoutHero({
  heroCopy1,
  heroCopy2,
  heroSubtitle,
  heroTitle,
}: PpCheckoutHero): React.ReactElement {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const styles = {
    banner: css`
      background-color: #161616;
      background-size: contain;
    `,
    hero: css`
      margin-top: ${theme.spacing(10)};
    `,
    heroBottom: css`
      ${theme.breakpoints.up('sm')} {
        padding-top: ${theme.spacing(10)};
      }
    `,
    visaMastercard: css`
      ${theme.breakpoints.down('md')} {
        max-width: 150px;
      }
    `,
  };
  return (
    <Stack alignItems="center" css={styles.hero} spacing={10} textAlign="center">
      <CheckoutBanner
        linkToBuy
        background="https://res.cloudinary.com/noahapp/image/upload/v1676992827/Website/buy-now-not-later-hero_pnpe7i.jpg"
        cssObject={styles.banner}
        subtitle={heroSubtitle}
        title={heroTitle}
      />
      <Stack alignItems="center" css={styles.heroBottom} spacing={2}>
        <Stack>
          <Typography variant={isExtraSmall ? 'bodyHeadingS' : 'h4'}>{heroCopy1}</Typography>
          <Typography color="text.secondary" variant={isExtraSmall ? 'bodyHeadingS' : 'h4'}>
            {heroCopy2}
          </Typography>
        </Stack>
        <img
          alt="visa mastercard"
          css={styles.visaMastercard}
          src="https://res.cloudinary.com/noahapp/image/upload/v1677062643/Website/Frame_41197_v3zs3w.png"
        />
        <ArrowsDown />
      </Stack>
    </Stack>
  );
}
