import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type {
  MarkdownRemarkFrontmatterFlexible,
  MarkdownRemarkFrontmatterGlobal,
  MarkdownRemarkFrontmatterInstant,
  Maybe,
} from '../../../generated/types';
import { GridCard } from './GridCard';
import { GridCardTitle } from './GridCardTitle';

type PpGridGlobalInstant = {
  data:
    | Maybe<Array<Maybe<MarkdownRemarkFrontmatterGlobal>>>
    | Maybe<Array<Maybe<MarkdownRemarkFrontmatterInstant>>>
    | Maybe<Array<Maybe<MarkdownRemarkFrontmatterFlexible>>>;
  title: 'global' | 'instant' | 'flexible';
};

export function GridGlobalInstantFlexible({
  data,
  title,
}: PpGridGlobalInstant): ReactElement | null {
  const theme = useTheme();
  if (!data) {
    return null;
  }

  const styles = {
    gridItem: css`
      ${theme.breakpoints.between('sm', 'lg')} {
        flex-basis: 50%;
      }
    `,
  };
  return (
    <Grid item css={styles.gridItem} lg={4} xs={12}>
      <GridCardTitle title={title} />
      <Stack>
        {data.map((card) => {
          if (!card) {
            return null;
          }
          return (
            <GridCard
              key={card.id}
              comingSoon={card.comingSoon}
              copy={card.copy}
              title={card.title}
            />
          );
        })}
      </Stack>
    </Grid>
  );
}
