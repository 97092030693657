//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgUsdcoin } from '../icons/Usdcoin';

export function UsdcoinIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgUsdcoin} data-qa="UsdcoinIcon">
      <svg />
    </SvgIcon>
  );
}
