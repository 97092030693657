import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CloseIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseIcon';
import { useToggleDialog } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';

type PpInfoDialog = {
  details: React.ReactElement;
};

export const InfoDialog = forwardRef<TpDialogToggle, PpInfoDialog>(({ details }, ref) => {
  const { open, toggle } = useToggleDialog({ ref });
  const theme = useTheme();

  const styles = {
    box: css`
      padding: ${theme.spacing(1)};
      position: relative;
    `,
    closeButton: css`
      position: absolute;
      z-index: 1;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(1)};
    `,
    dialogContent: css`
      border-radius: ${theme.borderRadius.md};
      border: 1px solid ${theme.palette.websiteBrand.primary};
    `,
  };

  return (
    <Dialog disableRestoreFocus open={open} onClose={toggle}>
      <DialogContent css={styles.dialogContent}>
        <IconButton css={styles.closeButton} size="small" onClick={toggle}>
          <CloseIcon />
        </IconButton>
        <Stack css={styles.box}>{details}</Stack>
      </DialogContent>
    </Dialog>
  );
});

InfoDialog.displayName = InfoDialog.name;
