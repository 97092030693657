import type { SVGProps } from 'react';

export function SvgImportExport(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.205 7.089v7.216c0 .601.488 1.09 1.09 1.09h2.34a1.09 1.09 0 0 0 1.09-1.09V7.089h2.526c.987 0 1.466-1.207.747-1.884L17.212.702a1.09 1.09 0 0 0-1.494 0l-4.786 4.503c-.719.677-.24 1.884.747 1.884zm-8.931 9.82V9.694c0-.602.488-1.09 1.09-1.09h2.341c.602 0 1.09.488 1.09 1.09v7.215h2.526c.987 0 1.466 1.207.747 1.884l-4.786 4.503a1.09 1.09 0 0 1-1.494 0l-4.786-4.503c-.719-.677-.24-1.884.747-1.884z"
        fill="currentColor"
      />
    </svg>
  );
}
