//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgReceive } from '../icons/Receive';

export function ReceiveIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgReceive} data-qa="ReceiveIcon">
      <svg />
    </SvgIcon>
  );
}
