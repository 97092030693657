import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterTransparent, Maybe } from '../../../generated/types';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { GridCard } from './GridCard';
import { GridCardTitle } from './GridCardTitle';

type PpGridTransparent = {
  data: Maybe<Array<Maybe<MarkdownRemarkFrontmatterTransparent>>>;
};

export function GridTransparent({ data }: PpGridTransparent): ReactElement | null {
  const theme = useTheme();
  const { isMedium } = useBreakpoints();
  if (!data) {
    return null;
  }

  const styles = {
    gridContainer: css`
      ${theme.breakpoints.up('sm')} {
        flex-direction: row;
        flex-wrap: wrap;
      }
      ${theme.breakpoints.up('lg')} {
        flex-wrap: nowrap;
      }
    `,
  };
  return (
    <Grid item lg={12} sm={6} xs={12}>
      <GridCardTitle title="Transparent" />
      <Grid container columnSpacing={isMedium ? 0 : 24} css={styles.gridContainer}>
        {data.map((card) => {
          if (!card) {
            return null;
          }
          return (
            <GridCard
              key={card.id}
              gridItem
              comingSoon={card.comingSoon}
              copy={card.copy}
              title={card.title}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
