import type { ReactElement } from 'react';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { BitrefillIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitrefillIcon';
import { LightningIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LightningIcon';
import { PlaceholderIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlaceholderIcon';
import { RewardsIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/RewardsIcon';
import { UsdcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UsdcoinIcon';
import { UsdtIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UsdtIcon';
import { useWebsiteContext } from '../context';
import { FeatureWithInfo } from './FeatureWithInfo';

type PpFeatureDetails = {
  feature: string;
};

export function FeatureDetails({ feature }: PpFeatureDetails): ReactElement | null {
  const { buySellResultInfo } = useWebsiteContext();

  switch (feature) {
    case 'usdc':
      return <FeatureWithInfo icon={<UsdcoinIcon />} text={buySellResultInfo?.usdc as string} />;
    case 'usdt':
      return <FeatureWithInfo icon={<UsdtIcon />} text={buySellResultInfo?.usdt as string} />;
    case 'lightning':
      return (
        <FeatureWithInfo
          icon={<LightningIcon fontSize="small" />}
          text={buySellResultInfo?.transfers as string}
        />
      );
    case 'rewards':
      return (
        <FeatureWithInfo
          icon={<RewardsIcon fontSize="small" />}
          text={buySellResultInfo?.rewards as string}
        />
      );
    case 'bitcoin':
      return (
        <FeatureWithInfo
          icon={<BitcoinIcon fontSize="small" />}
          text={buySellResultInfo?.onChain as string}
        />
      );
    case 'bitrefill':
      return (
        <FeatureWithInfo
          icon={<BitrefillIcon fontSize="small" />}
          text={buySellResultInfo?.bitrefill as string}
        />
      );
    case 'buyAndSell':
      return (
        <FeatureWithInfo
          icon={<BitcoinIcon fontSize="small" />}
          text={buySellResultInfo?.buyAndSell as string}
        />
      );
    default:
      return <PlaceholderIcon />;
  }
}
