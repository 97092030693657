import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgImportExport } from '../icons/ImportExport';

export function ImportExportHorizontalIcon({ sx, ...rest }: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      {...rest}
      component={SvgImportExport}
      data-qa="ImportExportIcon"
      sx={{ transform: 'rotate(90deg)', ...sx }}
    />
  );
}
