import { useLocalization } from 'gatsby-theme-i18n';

type TpUseLocalizedCopy = {
  comingSoonCopy: string;
  learnMoreCopy: string;
};

const spanishCopy = { comingSoonCopy: 'Próximamente', learnMoreCopy: 'Aprende más' };
const englishCopy = { comingSoonCopy: 'Coming Soon', learnMoreCopy: 'Learn more' };
const lithuanianCopy = { comingSoonCopy: 'Netrukus', learnMoreCopy: 'Sužinokite daugiau' };

export function useLocalizedCopy(): TpUseLocalizedCopy {
  const { locale } = useLocalization();
  switch (locale) {
    case 'es':
      return spanishCopy;
    case 'lt':
      return lithuanianCopy;
    default:
      return englishCopy;
  }
}
