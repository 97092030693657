import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useWebsiteContext } from '../context';
import { FeatureDetails } from './FeatureDetails';
import type { TpCountry } from './types';

type PpBuySellResult = {
  country: TpCountry;
};

export function BuySellResult({ country }: PpBuySellResult): React.ReactElement | null {
  const theme = useTheme();
  const { buySellResultInfo } = useWebsiteContext();

  if (!country) {
    return null;
  }

  const styles = {
    gridItem: css`
      ${theme.breakpoints.down('lg')} {
        margin-top: ${theme.spacing(2)};
      }
    `,
  };

  if (country?.noahBan) {
    return (
      <Grid container>
        <Grid item css={styles.gridItem}>
          <Typography color="text.secondary" variant="paragraphBodyS">
            {buySellResultInfo?.notAvailable}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item lg={6} xs={12}>
        <FeatureDetails feature="bitcoin" />
        <FeatureDetails feature="rewards" />
        <FeatureDetails feature="lightning" />
        <FeatureDetails feature="bitrefill" />
      </Grid>
      <Grid item lg={6} xs={12}>
        {country?.buySell && <FeatureDetails feature="buyAndSell" />}
        {country.stablecoins && (
          <Fragment>
            <FeatureDetails feature="usdc" />
            <FeatureDetails feature="usdt" />
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
}
