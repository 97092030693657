import type { SVGProps } from 'react';

export function SvgArrowUpThick(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 22 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.935 1.49a1.438 1.438 0 0 1 2.192.003l9.157 10.817a.719.719 0 0 1-.549 1.183H15.66v9.054c0 .794-.644 1.437-1.438 1.437h-6.39a1.44 1.44 0 0 1-1.437-1.438v-9.053h-5.13a.719.719 0 0 1-.546-1.185z"
        fill="currentColor"
      />
    </svg>
  );
}
