import type { SVGProps } from 'react';

export function SvgBanknote(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 17"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.571.23C1.193.23 0 1.304 0 2.717v11.4C0 15.528 1.193 16.6 2.571 16.6H21.43c1.378 0 2.571-1.072 2.571-2.486V2.716C24 1.303 22.807.231 21.429.231zM12 4.988a3.429 3.429 0 1 0 0 6.857 3.429 3.429 0 0 0 0-6.857M2.83 3.875c0-.592.48-1.072 1.072-1.072h1.012a1.071 1.071 0 1 1 0 2.143H3.902c-.592 0-1.072-.48-1.072-1.071m16.256 8.01a1.071 1.071 0 1 0 0 2.143H20.1a1.071 1.071 0 1 0 0-2.143h-1.012Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
