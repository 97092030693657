//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgCustomize } from '../icons/Customize';

export function CustomizeIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgCustomize} data-qa="CustomizeIcon">
      <svg />
    </SvgIcon>
  );
}
