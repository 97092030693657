import type { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { Autocomplete, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';

type PpBuySellAutocomplete = {
  id: string;
  label: string;
  options: (string | null | undefined)[] | undefined;
  setState: Dispatch<SetStateAction<string | null>>;
};

export function BuySellAutocomplete({
  id,
  label,
  options,
  setState,
}: PpBuySellAutocomplete): React.ReactElement | null {
  const theme = useTheme();

  if (!options) {
    return null;
  }

  const styles = {
    textField: css`
      .MuiOutlinedInput-root {
        padding: 0;
        border: 2px solid ${theme.palette.websiteBrand.greyscale.darkGrey};
      }
      .Mui-focused {
        border-color: ${theme.palette.websiteBrand.primary};
      }
      .MuiOutlinedInput-root input {
        border: none;
      }
      .MuiAutocomplete-popupIndicator {
        background-color: ${theme.palette.websiteBrand.greyscale.darkGrey};
        border: 2px solid ${theme.palette.websiteBrand.greyscale.darkGrey};
        border-radius: ${theme.borderRadius.sm};
        margin-top: ${theme.spacing(0.25)};
        height: ${theme.spacing(3)};
        width: ${theme.spacing(4)};
        color: ${theme.palette.websiteBrand.greyscale.white};
      }
      .MuiAutocomplete-popupIndicator:hover,
      .MuiAutocomplete-popupIndicatorOpen {
        background-color: ${theme.palette.websiteBrand.greyscale.white};
        border-color: ${theme.palette.websiteBrand.primary};
        color: ${theme.palette.websiteBrand.primary};
        transition: 0.1s background-color linear;
      }
    `,
  };

  return (
    <Autocomplete
      disableClearable
      fullWidth
      id={id}
      options={options}
      popupIcon={<ChevronDownIcon fontSize="small" />}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          aria-label={label}
          css={styles.textField}
          placeholder={label}
          variant="outlined"
        />
      )}
      onChange={(_event, newValue: string | null | undefined): void => {
        if (!newValue) {
          return;
        }
        setState(newValue);
      }}
      onInputChange={(): void => setState(null)}
    />
  );
}
