import { css } from '@emotion/react';
import { Card, Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowDownThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ArrowDownThickIcon';
import { ArrowUpThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ArrowUpThickIcon';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import { GlobeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/GlobeIcon';
import { ImportExportIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ImportExportIcon';
import { useBreakpoints } from '../../components/hooks/useBreakpoints';
import { useLocalizedCopy } from '../../components/hooks/useLocalizedCopy';

type PpCheckoutCarouselCard = {
  comingSoon: boolean | null | undefined;
  copy: string | null | undefined;
  index: number;
};

const icons = [
  <ArrowDownThickIcon key="arrowDown" />,
  <ArrowUpThickIcon key="arrowUp" />,
  <CircleTickIcon key="circleTick" />,
  <ImportExportIcon key="importExport" />,
  <GlobeIcon key="globe" />,
];

export function CheckoutCarouselCard({
  comingSoon,
  copy,
  index,
}: PpCheckoutCarouselCard): React.ReactElement | null {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const { comingSoonCopy } = useLocalizedCopy();

  const styles = {
    card: css`
      background-color: ${theme.palette.websiteBrand.greyscale.black};
      height: 325px;
      width: 100%;
      max-width: 250px;
      padding: ${theme.spacing(2.5)};
      margin: ${theme.spacing(1)};
      text-align: left;
      border-radius: ${theme.borderRadius.lg};
      ${theme.breakpoints.down('sm')} {
        height: 300px;
        max-width: 235px;
      }
    `,
    comingSoon: css`
      width: fit-content;
      color: ${theme.palette.websiteBrand.greyscale.white};
      border-color: ${theme.palette.websiteBrand.greyscale.white};
    `,
  };
  return (
    <Card css={styles.card}>
      <Stack alignItems="flex-start" height="100%" justifyContent="space-between">
        {icons[index]}
        <Stack spacing={1}>
          <Typography color="text.white" variant={isExtraSmall ? 'bodyHeadingM' : 'bodyHeadingS'}>
            {copy}
          </Typography>
          {comingSoon && <Chip css={styles.comingSoon} label={comingSoonCopy} />}
        </Stack>
      </Stack>
    </Card>
  );
}
